<template>
  <div>
    <div class="grid gap-y-6 py-4 pr-8">
      <div
        class="grid grid-flow-col justify-start items-center ml-2 cursor-pointer w-fit"
        @click="gotoApiOrders"
      >
        <TrailIcon icon="local:arrow-left" :size="16" />

        <p class="mx-3 text-trail-title4 text-trail-text-body-normal">
          {{ $t('orderDetailBacktoOrders') }}
        </p>
      </div>

      <h2>{{ $t('orderDetailTitle') }}</h2>

      <OrderDetails v-if="orderId" :order-id="orderId" :is-api="true" />
    </div>
  </div>
</template>

<script setup lang="ts">
definePage({
  name: 'api-order-detail',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\Package\\Models\\Order'],
  },
})

const router = useRouter()
const route = useRoute<'api-order-detail'>()
const regex = /-0*([1-9]\d*)$/
const orderId = computed(() => route.params.id?.match(regex)?.[1])
const gotoApiOrders = () => router.push('/api-orders')
</script>
