<template>
  <div
    v-if="store.isImpersonated"
    class="relative m-auto top-24 left-0 w-full text-center z-4 -mt-0.5"
  >
    <TrailBanner type="warning">
      <template #default>
        <div class="w-screen text-center">
          {{ $t('impersonationInfoBannerMessage') }}
          <strong>{{ store.userFullName }}</strong>

          <a
            href="#"
            class="ml-2 underline"
            @click.prevent="exitImpersonation"
            >{{ $t('exitImpersonation') }}</a
          >
        </div>
      </template>
    </TrailBanner>
  </div>

  <main
    :class="[
      'relative bg-trail-background-primary w-full min-h-[calc(100%_-_93px)]',
      { 'mt-24 relative -top-0.5': store.isImpersonated },
    ]"
    data-testid="app-wrapper"
  >
    <div>
      <TopNavigationMobile v-if="isMobile" data-testid="base-header-mobile" />

      <TopNavigation v-else data-testid="base-header" />
    </div>

    <Transition name="mobilemenu">
      <SubNavigation
        v-if="isDesktop || userInterfaceStore.sidebarOpened"
        class="w-64 py-6 px-4 overflow-y-auto bg-trail-background-primary h-[calc(100%-60px)] shadow-trail-background-primary"
        :class="{
          'w-full mt-15 pb-20': isMobile,
          'h-[calc(100%-85px)]': isTablet,
          'h-[calc(100%-160px)]': isDesktop,
        }"
        data-testid="base-sidebar"
      />
    </Transition>

    <div
      class="transition-all duration-150 z-20"
      :class="isDesktop ? 'ltr:ml-64 rtl:mr-64' : ''"
      data-testid="content-wrapper"
    >
      <div
        class="lg:p-8 md:p-6 p-4 gap-5 z-[0] bg-trail-background-primary"
        :class="[
          !isMobile ? '!pb-16 mt-[93px]' : 'mt-16',
          isTablet && userInterfaceStore.sidebarOpened
            ? 'blur-sm brightness-75 pointer-events-none select-none h-screen !z-0'
            : '',
          !buyEsimStore?.isShoppingBagEmpty &&
          !authStore?.isOnlyAPIPartnerStream &&
          !hideShoppingBagBar
            ? '!pt-0'
            : '',
        ]"
        data-testid="page-content"
      >
        <ShoppingBagBar
          v-if="
            !buyEsimStore?.isShoppingBagEmpty &&
            !authStore?.isOnlyAPIPartnerStream &&
            !hideShoppingBagBar
          "
          class="mb-4"
          data-testid="shopping-bag-bar"
        />

        <div
          class="col-span-20 transition-all duration-150 container-fluid"
          data-testid="page-container"
        >
          <router-view v-slot="{ Component }">
            <div v-if="Component">
              <transition name="router-animation" mode="out-in" appear>
                <component :is="Component"></component>
              </transition>
            </div>
          </router-view>
        </div>

        <NavigationFooter
          v-if="isMobile && !userInterfaceStore.sidebarOpened"
          class="w-full z-50"
          data-testid="base-footer"
        />
      </div>
    </div>

    <NavigationFooter
      v-if="!isMobile && height > 700"
      class="w-full fixed bottom-0 z-50 bg-trail-background-primary"
      data-testid="base-footer"
    />
  </main>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useBuyEsimStore } from '@/stores/buy-esim.store'
import { useInterfaceStore } from '@/stores/userInterface'
import { useAuthStore } from '@/stores/auth'
import { useResponsiveness } from '@/composables/responsiveness'
import { formatAmount } from '@/utils/formatAmount'
import { useTrailToast } from '@/composables/Trail/useTrailToast'
import type { MessageType } from '@/api/types/ablyTypes.ts'
import { useWindowSize } from '@vueuse/core'
import { logout } from '@/utils/auth'

const store = useAuthStore()
const { positive, negative } = useTrailToast()
const { t } = useI18n({ useScope: 'global' })
const { height } = useWindowSize()

definePage({
  meta: { requiresAuth: true },
})

const route = useRoute()
const authStore = useAuthStore()
const userInterfaceStore = useInterfaceStore()
const buyEsimStore = useBuyEsimStore()
const { isMobile, isTablet, isDesktop } = useResponsiveness()

const exitImpersonation = async () => {
  try {
    await logout()
    await authStore.logout()
    positive({ content: t('messages.signout.title') })
  } catch (error) {
    console.error('Error ending impersonation:', error)
  }
}

const subscribeAbly = async () => {
  const channel = await authStore.ablyChannel

  channel.subscribe('order.status.changed', (message: MessageType) => {
    switch (message.data.status) {
      case 'COMPLETED': {
        positive({ content: t('orderStatusCompletedNotification') })
        break
      }

      case 'CANCELLED': {
        negative({ content: t('orderStatusCancelledNotification') })
        break
      }
    }
  })

  channel.subscribe(
    'finance.transaction.status.changed',
    (message: MessageType) => {
      const { reference_id_short, amount, status } = message.data
      if (status === 'completed' && amount?.amount > 0 && reference_id_short) {
        positive({
          content: t('creditStatusCompletedNotification', {
            amount: formatAmount(amount),
            referenceId: reference_id_short,
          }),
        })
      }
    }
  )

  channel.subscribe(
    'sims.share_by_email.status.changed',
    (message: MessageType) => {
      if (message.data.status === 'ERROR') {
        negative({ content: t('shareModalEmailError') })
      }
    }
  )
}

const hideShoppingBagBar = computed(() =>
  ['esim-store', 'esim-store-checkout'].includes(route.name)
)

onMounted(async () => {
  await subscribeAbly()
})
</script>
